import { React, useState, useEffect, useCallback, useRef } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import { Button, IconButton, Tooltip, Divider, Autocomplete, TextField, Avatar, Grid, CircularProgress } from '@mui/material'
import NotificationSound from 'images/music/notification.wav'
import { requestToken, onMessageListener } from 'index'
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton'
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu'
import clsx from 'clsx'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import patientDetails from '../../../../services/patient/patientDetails/api'
import PracticeListAPI from 'services/CalendarApi/PracticeList/api'
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import _ from 'lodash'
import AddPatient from 'app/sharedComponents/addPatient/addPatient'
import SearchField from '../../../sharedComponents/Search/index'
import { customerDataList } from '../../../../services/patient/patientDetails/actions/index'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { doctorRoleUuid, setCurrentSelectedClient } from '../../../../services/patient/roleBasedUuid/action'
import { walkthroughSkipped, updateAccountSteps } from 'services/walkthrough/action'
import { openAddPatient, closeAddPatient } from '../../../../services/Client/actions'
import { addAppointmentModal, setApptOpenedFrom } from 'services/Appointments/action'
import { Link } from 'react-router-dom'
import history from '@history'
import CloseIcon from '@mui/icons-material/Close'
import { customerListApiTriggerFn } from '../../../../services/patient/roleBasedUuid/action'
import loginApi from 'services/login/api'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import secureLocalStorage from 'react-secure-storage'
import { useSnackbar } from 'notistack'
// import firebase from 'firebase/compat/app'
import themesConfig from 'app/fuse-configs/themesConfig'
import 'firebase/database'
import { decryption, getImgUrl } from 'utils'
import AddAppointment from 'app/main/AddAppointment'
import HeaderNotification from 'app/sharedComponents/HeaderNotification'
import { updateMastUuid, updateTentUserUuid, setUserName, updateEmail, updateOwner, tentTypeId } from '../../../session/actions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { setCurrentPractice } from 'services/DynamicMenu/actions'
import MoreOptionsHeader from 'app/sharedComponents/MoreOptionsHeader'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, FEATURES_LIST, PERMISSION_TYPE } from '../../../../constants'
import { updateCurrentIndex } from 'services/sidemenu/action'

const useStyles = makeStyles((theme) => ({
	sec1: {
		display: 'flex',
		alignItems: 'center',
		paddingInlineStart: 10,
		gap: 8,
	},
	sec2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	sec21: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
		justifyContent: 'center',
	},
	addBtn: {
		marginInlineStart: 10,
		color: themesConfig.lyfngo.palette.light.main,
		background: themesConfig.lyfngo.palette.tertiary.main,
		'&:hover': {
			color: themesConfig.lyfngo.palette.light.main,
			background: themesConfig.lyfngo.palette.tertiary.dark,
		},
	},
	iconBtn: {
		'&:hover .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
		'&:hover': {
			background: themesConfig.lyfngo.palette.light.main,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 'lighter',
		},
	},
}))

// const TooltipComponent = withStyles({
// 	tooltip: {
// 		color: themesConfig.lyfngo.palette.light.main,
// 		backgroundColor: '#004397',
// 		fontSize: 10,
// 	},
// })(Tooltip)
function ToolbarLayout1(props) {
	const classes = useStyles()
	const audioPlayer = useRef(null)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
	const navbar = useSelector(({ fuse }) => fuse.navbar)
	const toolbarTheme = useSelector(selectToolbarTheme)
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTablet = useMediaQuery(theme.breakpoints.down('md'))
	const showSearch = useMediaQuery(theme.breakpoints.down('lg'))
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid, userName, BearerToken } = state
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	// const docDriveUuid = useSelector((state) => state?.dynamicMenu?.currentPractice?.docDriveUuid || null)
	const deviceId = useSelector((states) => states?.Authentication.deviceId)
	const { groupName, docDriveUuid } = mainmenuState
	const practicelist = useSelector((state) => state.dynamicMenu?.practiceListGlobal)
	const isFetchingPracticeList = useSelector((state) => state.dynamicMenu?.isFetchingPracticeList)
	const [practiceName, setPracticeName] = useState('')
	const addAppointmentPermission = usePermissions(ROLE_MODULES.CALENDAR, FEATURES_LIST.APPOINTMENT)
	const isCare = _.isEqual(groupName, 'health care')
	function playAudio() {
		audioPlayer.current.play()
	}
	// const getCustomerListDetails = useCallback(() => {
	// 	const onSuccess = (res) => {
	// 		const successData = decryption(res)
	// 		if (successData?.status === 'success') {
	// 			dispatch(customerDataList(successData?.data))
	// 			dispatch(customerListApiTriggerFn())
	// 		}
	// 	}

	// 	const onFailure = (err) => {}
	// 	if (mastuuid) {
	// 		patientDetails.getCustomerDetails(mastuuid).then(onSuccess, onFailure)
	// 	}
	// }, [mastuuid, dispatch])

	// useEffect(() => {
	// 	getCustomerListDetails()
	// }, [getCustomerListDetails, mastuuid])

	const handleClickOpen = () => {
		dispatch(openAddPatient())
	}
	const handleClose1 = () => {
		dispatch(closeAddPatient())
	}

	const onClickAppointment = () => {
		dispatch(addAppointmentModal(true))
		dispatch(setApptOpenedFrom('openbuttonCalendar'))
	}

	const openPatient = useSelector((state) => state?.Client?.openAddPatient?.open)
	const [getMedeAccessRole, setGetMedeAccessRole] = useState([])
	const getMedeAccessData = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setGetMedeAccessRole(res?.data?.data)
			}
		}
		const onFailure = () => {}
		if (mastuuid) {
			patientDetails.getMedeAccessRole(mastuuid).then(onSuccess, onFailure)
		}
	}, [mastuuid])

	useEffect(() => {
		let temparr = []
		_.map(getMedeAccessRole, (item) => {
			if (item?.mastRoleName === 'Doctor') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Trainer') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Therapist') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Teacher') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Coach') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Beautician') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			} else if (item?.mastRoleName === 'Yoga Instructor') {
				secureLocalStorage.setItem('doctorRoleUuid', item?.mastRoleUuid)
				dispatch(doctorRoleUuid(item?.mastRoleUuid))
			}
			temparr.push(item?.mastRoleUuid)
		})
	})
	useEffect(() => {
		getMedeAccessData()
	}, [getMedeAccessData])
	const tourRestart = (data) => {
		if (data) {
			dispatch(walkthroughSkipped())
			secureLocalStorage.setItem('skipTour', false)
			secureLocalStorage.setItem('isNewUser', true)
			secureLocalStorage.setItem('persistTour', { run: true, stepIndex: 0 })
			window.location.reload()
		}
	}
	const restart = secureLocalStorage.getItem('skipTour')
	const [isTokenFound, setTokenFound] = useState(false)
	const [notification, setNotification] = useState({ title: '', body: '' })
	// const [notificationList, setNotificationList] = useState({})
	// const [notiCount, setNotiCount] = useState(0)

	const action = (snackbarId) => (
		<>
			<Link to='/chats' underline='hover' style={{ color: '#5151ff', fontWeight: 600 }}>
				{'Click Here'}
			</Link>
			<IconButton
				onClick={() => {
					closeSnackbar(snackbarId)
				}}
			>
				<CloseIcon style={{ color: '#fff' }} />
			</IconButton>
		</>
	)

	onMessageListener()
		.then((payload) => {
			history?.location?.pathname !== '/chats' && playAudio()
			history?.location?.pathname !== '/chats' && enqueueSnackbar(payload?.notification?.body, { variant: 'default', action })
			setNotification({
				title: payload.notification.title,
				body: payload.notification.body,
			})
			// const onSuccess = (res) => {
			// 	if (res?.data?.status === 'success') {
			// 		setNotificationList(res?.data?.data)
			// 		setNotiCount(res?.data?.totalCount)
			// 		setNotification({ title: '', body: '' })
			// 	}
			// }
			// const onFailure = (err) => {
			// 	setNotificationList({})
			// }

			// let body = isCare && isAdminOwnerRecp ? { tentUuid: mastuuid } : { tentUuid: mastuuid, tentUserUuid: uuid }
			// chartingAPI.getPaymentNotification(body).then(onSuccess, onFailure)
		})
		.catch((err) => {})

	useEffect(() => {
		let data
		let deviceToken
		async function tokenFunc() {
			data = await requestToken(setTokenFound)
			deviceToken = secureLocalStorage.getItem('deviceTok', data)
			if (data) {
				const onSuccess = (res) => {
					secureLocalStorage.setItem('deviceTok', data)
				}
				const onFailure = (err) => {}
				!deviceToken && BearerToken && loginApi.setDeviceToken(data, deviceId).then(onSuccess, onFailure)
			}
		}
		tokenFunc()
	}, [BearerToken, deviceId, setTokenFound])

	// // Relatime Notification
	// const fetchNotification = useCallback(() => {
	// 	firebase
	// 		.database()
	// 		.ref(`ConsultPushNotifcation`)
	// 		.on('value', function (snapshot) {
	// 			if (snapshot.val()) {
	// 				const onSuccess = (res) => {
	// 					if (res?.data?.status === 'success') {
	// 						setNotificationList(res?.data?.data)
	// 						setNotiCount(res?.data?.totalCount)
	// 						setNotification({ title: '', body: '' })
	// 					}
	// 				}
	// 				const onFailure = (err) => {
	// 					setNotificationList({})
	// 				}
	// 				let body = isCare && isAdminOwnerRecp ? { tentUuid: mastuuid } : { tentUuid: mastuuid, tentUserUuid: uuid }
	// 				// chartingAPI.getPaymentNotification(body).then(onSuccess, onFailure)
	// 			}
	// 		})
	// }, [isAdminOwnerRecp, isCare, mastuuid, uuid])

	// useEffect(() => {
	// 	fetchNotification()
	// }, [fetchNotification])

	// The useffect triggers for saving the user post Time Accessed
	useEffect(() => {
		if (!_.isEmpty(uuid)) {
			PracticeListAPI.postTimeAccessed(uuid).then(
				(res) => {
					if (res.data.status === 'success') {
						// do nothing
					}
				},
				() => {}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uuid, window.location.pathname])

	useEffect(() => {
		if (!_.isEmpty(practicelist)) {
			let currentPractice = _.find(practicelist, { masterTenantUuid: mastuuid, tentUserUuid: uuid })
			setPracticeName(currentPractice || {})
			secureLocalStorage.setItem('tentTypeId', currentPractice?.mastTentTypeUuid)
			dispatch(tentTypeId(currentPractice?.mastTentTypeUuid))
			dispatch(setCurrentPractice(currentPractice))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, practicelist, uuid])

	const getOptionLabel = (option) => option.practiceName || ''

	// const VariableWidth =
	// 	(practiceName?.practiceName?.length * 14 > 310
	// 		? 310
	// 		: practiceName?.practiceName?.length * 14 < 160
	// 		? 160
	// 		: practiceName?.practiceName?.length * 14) || 170

	// Actions to Happen when change of Practice
	useEffect(() => {
		// clearing the selceted client:
		dispatch(setCurrentSelectedClient(null))

		const pathname = window?.location?.pathname
		const splitedPath = pathname.split('/')

		if (!_.isEmpty(practiceName) && !_.isEmpty(splitedPath)) {
			let currentModuleName = splitedPath[1]

			if (_.isEqual(currentModuleName, 'calendar')) {
				history?.replace('/calendar?specialist=&leaf=cal&delegate=')
			} else if (_.isEqual(currentModuleName, 'setting')) {
				history?.replace('/setting')
			} else if (
				_.isEqual(currentModuleName, 'patients') ||
				_.isEqual(currentModuleName, 'clientinfo') ||
				_.isEqual(currentModuleName, 'clients') ||
				_.isEqual(currentModuleName, 'clientinfo')
			) {
				history?.replace('/clients')
			} else if (_.isEqual(currentModuleName, 'leadsinfo')) {
				history?.replace('/leads')
				// other module methods
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, isCare])

	// PAYTM Script LOADER after LOGIN
	const loadPaytmScript = useCallback(() => {
		if (BearerToken) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.crossOrigin = 'anonymous'
			script.src = 'https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/SpoDXu74486188491888.js'
			document.head.appendChild(script)

			// Cleanup: Remove the script when it's loaded
			script.onload = () => {
				// Optionally, you can perform actions after the script is loaded
				// console.log('Paytm script has been loaded.')
			}
		}
	}, [BearerToken])

	useEffect(() => {
		loadPaytmScript()
	}, [loadPaytmScript])

	return (
		<ThemeProvider theme={toolbarTheme}>
			<audio ref={audioPlayer} src={NotificationSound} />
			<AppBar id='fuse-toolbar' className={clsx('flex relative z-9999 shadow-md', props.className)} position='static'>
				<Toolbar className='p-0 min-h-48 md:min-h-64'>
					<Grid container xs={12} className={classes.mainContainer}>
						<Grid item xs={6} sm={5} lg={3} className={classes.sec1}>
							{config.navbar.display && config.navbar.position === 'left' && (
								<>
									<Hidden xsUp>
										<NavbarToggleButton className='w-40 h-40 p-0 mx-0 sm:mx-8' />
									</Hidden>
								</>
							)}
							{/* {isTablet ? null : (
								<div>
									<Avatar
										className='md:mx-4 w-40 h-40'
										src={docDriveUuid !== null ? getImgUrl(docDriveUuid, BearerToken) : isCare ? careEmptyIcon : NonCareEmptyIcon}
										alt={userName}
									/>
								</div>
							)} */}

							<div style={{ width: 230 }}>
								<Autocomplete
									id='combo-box-demo'
									options={practicelist}
									disablePortal
									disableClearable
									popupIcon={<KeyboardArrowDownIcon />}
									fullWidth
									// sx={{ width: VariableWidth }}
									onChange={(e, value) => {
										setPracticeName(value)
										dispatch(updateTentUserUuid(value?.tentUserUuid || uuid))
										secureLocalStorage.setItem('tentTypeId', value?.mastTentTypeUuid)
										dispatch(tentTypeId(value?.mastTentTypeUuid))
										dispatch(setUserName(value?.tentUserFirstName))
										dispatch(updateEmail(value?.tentUserEmail))
										dispatch(updateOwner(value?.owner))
										dispatch(updateMastUuid({ mastuuid: value?.masterTenantUuid || mastuuid, xeroStatus: value?.xeroConfigStatus || false }))
										if (window?.location?.pathname === '/profilesetup') {
											history.push('/profile')
										}
									}}
									getOptionLabel={getOptionLabel}
									value={practiceName}
									getOptionSelected={(option, value) => {
										return option?.practiceName === value?.practiceName
									}}
									className={classes.profileEstablishment}
									// clearIcon=''
									renderInput={(params) => (
										<TextField
											{...params}
											variant='standard'
											size='small'
											className={classes.establishmentName}
											placeholder='Establishment Name'
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
												style: {
													fontWeight: 'bold',
													paddingRight: 12,
												},
												endAdornment: <>{isFetchingPracticeList ? <CircularProgress color='inherit' size={20} /> : params.InputProps.endAdornment}</>,
											}}
											InputLabelProps={{
												shrink: false,
											}}
										/>
									)}
								/>
							</div>
						</Grid>

						<Grid item xs={6} sm={7} lg={9} className={classes.sec2}>
							{/* Global Search */}
							{!showSearch && (
								<div style={{ width: '50%' }}>
									<SearchField />
								</div>
							)}

							{/* Add Patient/Client */}
							{!isMobile && (
								<Tooltip title={isCare ? 'Add Patient' : 'Add Client'} followCursor>
									<Button
										onClick={() => {
											handleClickOpen()
											dispatch(updateAccountSteps({ run: false, stepIndex: 8 }))
										}}
										variant='contained'
										className={classes.addBtn}
										keepMounted
									>
										{<PersonAddAlt1Icon />}
									</Button>
								</Tooltip>
							)}

							{/* Add Appointment */}
							{!isMobile && _.get(addAppointmentPermission, PERMISSION_TYPE.CREATE, false) && (
								<Tooltip title='Add Appointment' followCursor>
									<Button onClick={() => onClickAppointment()} variant='contained' className={classes.addBtn} keepMounted>
										<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/add_appointment.svg`} alt='No drafts found' width='22' height='22' />
									</Button>
								</Tooltip>
							)}

							{/* More Options */}
							<div>
								<MoreOptionsHeader restart={restart} tourRestart={tourRestart} />
							</div>

							{/* Settings Icon */}
							{!isMobile && (
								<div>
									<Tooltip title='Settings' followCursor>
										<IconButton className={classes.iconBtn}>
											<SettingsOutlinedIcon
												disabled={history.location.pathname === '/setting'}
												onClick={() => {
													history.push('/setting')
													dispatch(updateCurrentIndex('setting'))
												}}
											/>
										</IconButton>
									</Tooltip>
								</div>
							)}

							{/* Notification */}
							<div>
								<HeaderNotification playAudio={playAudio} />
							</div>

							<Divider orientation='vertical' style={{ marginInlineEnd: 12 }} />

							{/* User Menu */}
							<UserMenu />

							{config.navbar.display && config.navbar.position === 'right' && (
								<>
									<Hidden lgDown>{!navbar.open && <NavbarToggleButton className='w-40 h-40 p-0 mx-0' />}</Hidden>

									<Hidden lgUp>
										<NavbarToggleButton className='w-40 h-40 p-0 mx-0 sm:mx-8' />
									</Hidden>
								</>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<AddPatient open={openPatient} handleClose1={() => handleClose1()} />
			<AddAppointment />
		</ThemeProvider>
	)
}

export default memo(ToolbarLayout1)
