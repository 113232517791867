import axios from 'axios'
import { API_ENDPOINTS } from '../../constants/index'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getEmbbededURL: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_S3_EMBBED_URL}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: false },
			params: { ...params },
		})
	},
	getQDashboard: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_QDASHBOARD_URL}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: false },
			params: { ...params },
		})
	},
	getChatHistory: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_AI_CHAT_HISTORY}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
			params: { ...params },
		})
	},
	getChatBotSessions: (mast_tent_uuid) => {
		return axios.get(`${API_ENDPOINTS.GET_CHATBOT_SESSIONS}/${mast_tent_uuid}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
		})
	},
}
