import React, { memo, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, Switch } from 'react-router'
import { styled } from '@mui/material/styles'
import FuseMessage from '@fuse/core/FuseMessage'
import { useSelector, useDispatch } from 'react-redux'
import FooterLayout1 from './components/FooterLayout1'
import LeftSideLayout1 from './components/LeftSideLayout1'
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1'
import RightSideLayout1 from './components/RightSideLayout1'
import ToolbarLayout1 from './components/ToolbarLayout1'
import PrivateRoutes from './privateRoute'
import Joyride from 'react-joyride'
import history from '@history'
import steps from './tourSteps'
import {
	walkthroughSkipped,
	updateAccountSteps,
	isAccountWalkthroughCompleted,
	isUserManagemnetWalkthroughCompleted,
	isSettingCalendarWalkthroughCompleted,
	isPricingWalkthroughCompleted,
	isDrugWalkthroughCompleted,
	isClinicalWalkthroughCompleted,
	isBillingWalkthroughCompleted,
	isPatientCardWalkthroughCompleted,
	isPatientMainWalkthroughCompleted,
} from '../../../services/walkthrough/action'
import ErrorFallback from './components/ErrorBoundary'
import { ROLE_MODULES } from '../../../constants'
import PracticeList from 'services/CalendarApi/PracticeList/api'
import { setBuyNow } from 'services/SubscriptionRedux/action'
import { updateTrialStatus } from 'services/DynamicMenu/actions'
import secureLocalStorage from 'react-secure-storage'
import themesConfig from 'app/fuse-configs/themesConfig'
import { decryption } from 'utils'
import _ from 'lodash'
import SuccessModal from 'app/main/apps/EventManagement/SuccessModal'
import GoogleSignInDialog from 'app/sharedComponents/GoogleSignInDialog'
const Login = React.lazy(() => import('app/main/login/Login'))
const LoginOld = React.lazy(() => import('app/main/apps/LoginOld'))
const DashBoard = React.lazy(() => import('app/main/apps/dashboard'))
const Feedback = React.lazy(() => import('app/main/apps/FeedbackNew'))
const WelcomeCare = React.lazy(() => import('app/main/welcomeScreen/welcomeCare'))
//  Enable if need the sign in
const Register = React.lazy(() => import('app/main/register/Register'))
const Otp = React.lazy(() => import('app/main/otp/Otp'))
const MicroSetup = React.lazy(() => import('app/main/ProfileSetup/MicroSetup'))
const GroupSelectionModule = React.lazy(() => import('app/main/groupSelection/groupSelection'))
const EmailVerify = React.lazy(() => import('app/main/EmailVerificationModal/EmailVerificationModal'))
const EmailSuccesfull = React.lazy(() => import('app/main/EmailSuccessful/EmailSuccessful'))
const StepperView = React.lazy(() => import('../../main/ProfileSetup/StepperView/StepperSlide'))
const SuccessMail = React.lazy(() => import('app/main/SuccessEmail/successmail'))
const ModuleSelection = React.lazy(() => import('app/main/moduleSelection/ModuleSelection'))
const ForgotPassword = React.lazy(() => import('app/main/forgot-password/ForgotPasswordPage'))
const ResetPassword = React.lazy(() => import('app/main/ResetPassword/index'))
const ForgotPasswordToken = React.lazy(() => import('app/main/ResetPassword/ResetModel'))
const ResetSuccessful = React.lazy(() => import('app/main/ResetSuccessful/resetSuccessful'))
const ChangeEmail = React.lazy(() => import('app/main/ChangeEmail/changeEmail'))
const ProfileSetup = React.lazy(() => import('app/main/ProfileSetup'))
const Error404 = React.lazy(() => import('app/main/404'))
const ComingSoon = React.lazy(() => import('app/main/ComingSoon'))
const Error403 = React.lazy(() => import('app/main/403'))
const Communication = React.lazy(() => import('app/main/apps/communication/Communication'))
const WhatsappCenterView = React.lazy(() => import('app/main/apps/communication/WhatsAppCenter/MessageView'))
const WhatsappCenterSend = React.lazy(() => import('app/main/apps/communication/WhatsAppCenter/SendMessage'))
const Consultation = React.lazy(() => import('app/main/apps/Consultation'))
const ProfileManagement = React.lazy(() => import('app/main/ProfileSetup/Profiletable/ProfileManagement'))
const ProfileStatus = React.lazy(() => import('../../main/ProfileSetup/StepperView/ProfileSubmit/index'))
const ProfileVerification = React.lazy(() => import('../../main/ProfileSetup/StepperView/ProfileSubmitUnder/index'))
const ProfileGoLive = React.lazy(() => import('../../main/ProfileSetup/StepperView/ProfileGoLive/index'))
const PharmPres = React.lazy(() => import('../../../app/main/apps/PharmaPres'))
const AccountSettings = React.lazy(() => import('../../main/apps/Account/AccountSettings'))
const AccountDetails = React.lazy(() => import('../../main/apps/Account/AccountDetails'))
const SettingLayout = React.lazy(() => import('../../main/apps/settings'))
const AccountSubscription = React.lazy(() => import('../../main/apps/Account/AccountSubscription'))
const Cart = React.lazy(() => import('../../main/apps/Account/AccountSubscription/Cart'))
const PaymentSuccess = React.lazy(() => import('../../main/apps/Account/AccountSubscription/Cart/PaymentSuccess'))
const PaymentFailure = React.lazy(() => import('../../main/apps/Account/AccountSubscription/Cart/paymentFailure'))
const ReportPage = React.lazy(() => import('../../main/apps/report'))
const OtpVerification = React.lazy(() => import('../../main/OtpVerification'))
const IndividualDashboard = React.lazy(() => import('app/main/apps/dashboard/IndividualDashboard'))
const ChartingBilling = React.lazy(() => import('../../main/apps/ChartingAndBilling/index'))
const Wallet = React.lazy(() => import('../../main/apps/Wallet'))
const InvoicHistory = React.lazy(() => import('app/main/apps/Account/AccountSubscription/store/InvoiceHistory'))
const PurchasedDetail = React.lazy(() => import('app/main/apps/Account/AccountSubscription/store/InvoiceHistory/PurchasedDetail'))
const OrderSummary = React.lazy(() => import('app/main/apps/Account/AccountSubscription/Cart/OrderSummary'))
const CalendarNew = React.lazy(() => import('app/main/apps/CalendarNew'))
const Inventory = React.lazy(() => import('app/main/apps/BackOffice/Inventory'))
const Expenses = React.lazy(() => import('app/main/apps/BackOffice/Expenses'))
const Products = React.lazy(() => import('app/main/apps/BackOffice/Products'))
const Activities = React.lazy(() => import('app/main/apps/BackOffice/Activities'))
// const CallNotification = React.lazy(() => import('../../sharedComponents/Notification/CallNotification'))
// const CallAcceptDialog = React.lazy(() => import('../../sharedComponents/CallAcceptDialog'))
const Client = React.lazy(() => import('../../main/apps/Client'))
const EndWalkthrough = React.lazy(() => import('./endWalkThroughDialog'))
const LeadsCustomerDetails = React.lazy(() => import('../../main/apps/Leads/CustomerDetails'))

const CustomerDetails = React.lazy(() => import('../../sharedComponents/PatientClientList/CustomerDetails'))
const TermsConditions = React.lazy(() => import('app/main/TermsandConditions'))
const AddClient = React.lazy(() => import('app/main/apps/Client/AddClient'))
const ExpirePlan = React.lazy(() => import('../../sharedComponents/ExpirePlan/index'))
const HelpCenter = React.lazy(() => import('app/main/apps/HelpCenter'))
const Events = React.lazy(() => import('app/main/apps/EventsManagement'))
const RegisterUserList = React.lazy(() => import('app/main/apps/Events/RegisterUserList'))
const Patients = React.lazy(() => import('app/main/apps/Patients'))
const CustomerDetailsPatients = React.lazy(() => import('../../sharedComponents/PatientClientList/CustomerDetails'))
const QuickSale = React.lazy(() => import('app/main/apps/QuickSale'))
const Calendar = React.lazy(() => import('app/main/apps/Calendar'))
const PatientCli = React.lazy(() => import('app/main/apps/PatientCli'))
const ChatTest = React.lazy(() => import('app/main/apps/ChatTest'))
const Chat = React.lazy(() => import('app/main/apps/Chat'))
const Leads = React.lazy(() => import('../../main/apps/Leads'))
const EventsManagement = React.lazy(() => import('../../main/apps/EventManagement'))
const CreateEventManagement = React.lazy(() => import('../../main/apps/EventManagement/CreateEvent'))
const EventInfo = React.lazy(() => import('../../main/apps/EventManagement/EventInfo'))
const LeadsDetail = React.lazy(() => import('../../main/apps/Leads/EditLeadDetails'))
const BreathingRate = React.lazy(() => import('app/sharedComponents/PatientClientList/HealthDasboard/BreathingRate'))
const AttendenceManagement = React.lazy(() => import('app/main/apps/AttendanceManagement'))
const DashbaordAI = React.lazy(() => import('app/main/apps/DashboardAI'))

const Root = styled('div')(({ theme, config }) => ({
	...(config.mode === 'boxed' && {
		clipPath: 'inset(0)',
		maxWidth: `${config.containerWidth}px`,
		margin: '0 auto',
		boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
	}),
	...(config.mode === 'container' && {
		'& .container': {
			maxWidth: `${config.containerWidth}px`,
			width: '100%',
			margin: '0 auto',
		},
	}),
}))

function Layout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
	const isAccountCompleted = useSelector((state) => state?.walkthrough?.isAccountCompleted || false)
	const isUsermanagementCompleted = useSelector((state) => state?.walkthrough?.isUsermanagementCompleted || false)
	const isSettingCalendarCompleted = useSelector((state) => state?.walkthrough?.isSettingCalendarCompleted || false)
	const isPriceCompleted = useSelector((state) => state?.walkthrough?.isPriceCompleted || false)
	const isDrugCompleted = useSelector((state) => state?.walkthrough?.isDrugCompleted || false)
	const isClinicalCompleted = useSelector((state) => state?.walkthrough?.isClinicalCompleted || false)
	const isBillingCompleted = useSelector((state) => state?.walkthrough?.isBillingCompleted || false)
	const isPatientCardCompleted = useSelector((state) => state?.walkthrough?.isPatientCardCompleted || false)
	const isPatientMainCompleted = useSelector((state) => state?.walkthrough?.isPatientMainCompleted || false)
	const isNewUser = useSelector((state) => state?.Authentication?.isNewUser)
	const tourState = useSelector((state) => state?.walkthrough?.account || { stepIndex: 0, run: false })
	const skipTour = useSelector((state) => state?.walkthrough?.isWalthroughSkip)
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const dynamicMenuFetching = useSelector((state) => state?.dynamicMenu?.isFetchingMenu || false)
	// const defaultPracticeMenu = useSelector((state) => state?.dynamicMenu?.currentPractice || {})
	const showLoaderThru = useSelector((state) => state?.loader?.showLoader)
	const { stepIndex, run } = tourState
	const dispatch = useDispatch()
	const auth = useSelector((states) => states.Authentication)
	const { mobileNo } = auth

	const [openSuccessModal, setOpenSuccessModal] = useState(true)

	useEffect(() => {
		isNewUser && secureLocalStorage.setItem('skipTour', false)
		if (!isAccountCompleted && !skipTour && isNewUser) {
			dispatch(updateAccountSteps({ run: true, stepIndex }))
		}
	}, [dispatch, isAccountCompleted, isNewUser, skipTour, stepIndex])

	const currentPath = history?.location?.pathname

	const [open, setOpen] = useState(false)

	const openTourModal = () => {
		setOpen(true)
	}
	const onClickCloseTour = () => {
		setOpen(false)
	}
	const onSubmitTourClose = () => {
		dispatch(walkthroughSkipped())
		secureLocalStorage.setItem('skipTour', true)
		secureLocalStorage.setItem('isNewUser', false)
		onClickCloseTour()
	}

	const [walkStatus, setWalkStatus] = useState('')

	const onWalkthroughChange = (data) => {
		const { index, action, lifecycle, status } = data
		action !== 'skip' && secureLocalStorage.setItem('skipTour', false)
		setWalkStatus(status)
		let isNext = action === 'next'
		let lifeCycleComplete = lifecycle === 'complete'
		let stepIndex = isNext ? index + 1 : index - 1
		if (isNext && lifeCycleComplete) {
			dispatch(updateAccountSteps({ run, stepIndex }))
			if (index === 0 && !isAccountCompleted && isNewUser && !skipTour && currentPath !== '/accountdetails') {
				history.push('/accountdetails')
				dispatch(isAccountWalkthroughCompleted(true))
			} else if (
				index === 1 &&
				isAccountCompleted &&
				!isUsermanagementCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=staffmanagement'
			) {
				history.push('/setting?tabId=staffmanagement')
				dispatch(isUserManagemnetWalkthroughCompleted(true))
			} else if (
				index === 2 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				!isSettingCalendarCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=calendar'
			) {
				history.push('/setting?tabId=calendar')
				dispatch(isSettingCalendarWalkthroughCompleted(true))
			} else if (
				index === 3 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				!isBillingCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=billing'
			) {
				history.push('/setting?tabId=billing')
				dispatch(isBillingWalkthroughCompleted(true))
			} else if (
				index === 4 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				isBillingCompleted &&
				!isPriceCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=pricing'
			) {
				history.push('/setting?tabId=pricing')
				dispatch(isPricingWalkthroughCompleted(true))
			} else if (
				index === 5 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				isBillingCompleted &&
				isPriceCompleted &&
				!isDrugCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=drug'
			) {
				history.push('/setting?tabId=drug')
				dispatch(isDrugWalkthroughCompleted(true))
			} else if (
				index === 6 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				isBillingCompleted &&
				isPriceCompleted &&
				isDrugCompleted &&
				!isClinicalCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/setting?tabId=clinicalnotes'
			) {
				history.push('/setting?tabId=clinicalnotes')
				dispatch(isClinicalWalkthroughCompleted(true))
			} else if (
				index === 7 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				isPriceCompleted &&
				isDrugCompleted &&
				isClinicalCompleted &&
				isBillingCompleted &&
				!isPatientCardCompleted &&
				isNewUser &&
				!skipTour &&
				currentPath !== '/clients'
			) {
				history.push('/clients')
				dispatch(isPatientCardWalkthroughCompleted(true))
			} else if (
				index === 8 &&
				isAccountCompleted &&
				isUsermanagementCompleted &&
				isSettingCalendarCompleted &&
				isPriceCompleted &&
				isDrugCompleted &&
				isClinicalCompleted &&
				isBillingCompleted &&
				isPatientCardCompleted &&
				!isPatientMainCompleted &&
				isNewUser &&
				!skipTour
			) {
				dispatch(isPatientMainWalkthroughCompleted(true))
			}
			// else if (index === 26 && currentPath !== '/report') {
			// 	history.push('/report')
			// 	isPatientMainWalkthroughCompleted(true)
			// }
		} else if (action === 'skip') {
			dispatch(walkthroughSkipped())
			secureLocalStorage.setItem('skipTour', true)
			secureLocalStorage.setItem('isNewUser', false)
		}
	}

	useEffect(() => {
		if (walkStatus === 'finished') {
			openTourModal()
		}
	}, [walkStatus])

	useEffect(() => {
		if (run === true && isNewUser) {
			secureLocalStorage.setItem('persistTour', { run: run, stepIndex: stepIndex })
		}
	}, [isNewUser, run, stepIndex])

	return (
		<Root id='fuse-layout' config={config} className='w-full flex'>
			<Joyride
				styles={{
					options: {
						zIndex: 999999,
					},
					buttonNext: {
						background: themesConfig.greyDark.palette.primary.main,
						paddingInline: 18,
						borderRadius: 20,
						fontSize: 16,
					},
				}}
				continuous={true}
				run={!dynamicMenuFetching && !showLoaderThru && isNewUser && !skipTour ? run : false}
				steps={steps}
				callback={onWalkthroughChange}
				showSkipButton={true}
				stepIndex={stepIndex}
				disableCloseOnEsc={true}
				disableOverlayClose={true}
				hideBackButton={true}
				hideCloseButton={true}
				spotlightPadding={0}
				scrollToFirstStep={true}
				disableScrolling={stepIndex === 1 ? true : stepIndex === 7 ? true : false}
				showProgress={true}
				locale={{
					last: 'Done',
					next: stepIndex === 0 && currentPath !== '/accountdetails' ? 'Go to account' : 'Next',
				}}
			/>

			{config.leftSidePanel.display && <LeftSideLayout1 />}
			<div className='flex flex-auto min-w-0'>
				{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}
				<main id='fuse-main' className='flex flex-col flex-auto min-h-screen min-w-0 relative z-10'>
					{config.toolbar.display && <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />}
					<div className='flex flex-col flex-auto min-h-0 relative z-10 '>
						<ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
							<Switch>
								<Route path='/' exact component={LoginOld} />
								<Route path='/login' component={Login} />
								{/* Enable if need the sign in */}
								<Route path='/register' component={Register} />
								<Route path='/terms' component={TermsConditions} />
								<Route path='/groupselection' component={GroupSelectionModule} />
								<Route path='/emailsuccesfull' component={EmailSuccesfull} />
								<Route path='/emailverification' component={EmailVerify} />
								<Route path='/successmail' component={SuccessMail} />
								<Route path='/establishmentselection' component={WelcomeCare} />
								<Route path='/otp' component={Otp} />
								<Route path='/forgotpassword' component={ForgotPassword} />
								<Route path='/resetpassword' component={ResetPassword} />
								<Route path='/resetsuccessful' component={ResetSuccessful} />
								<Route path='/forgetpasstokenverification' component={ForgotPasswordToken} />
								<Route path='/moduleselection' component={ModuleSelection} />
								<Route path='/changeemail' component={ChangeEmail} />
								<Route path='/comingsoon' component={ComingSoon} />
								<Route path='/microsetup' component={MicroSetup} />
								<Route path='/403' component={Error403} />
								{/* <Route path='/termsandconditions' component={TermsandConditions} /> */}
								{/* Private Routes */}
								<PrivateRoutes exact path='/setting' moduleName={ROLE_MODULES.SETTINGS} component={SettingLayout} />
								<PrivateRoutes exact path='/dashboard' moduleName={ROLE_MODULES.DASHBOARD} component={DashBoard} />
								<PrivateRoutes exact path='/products' moduleName={ROLE_MODULES.PRODUCTS} component={Products} />
								<PrivateRoutes exact path='/feedback' moduleName={ROLE_MODULES.FEEDBACK} component={Feedback} />
								<PrivateRoutes exact path='/quicksale' moduleName={ROLE_MODULES.QUICKSALE} component={QuickSale} />
								<PrivateRoutes exact path='/report' moduleName={ROLE_MODULES.REPORT} component={ReportPage} />
								<PrivateRoutes exact path='/communication' moduleName={ROLE_MODULES.COMMUNICATION} component={Communication} />
								<PrivateRoutes exact path='/wallet' moduleName={ROLE_MODULES.COMMUNICATION} component={Wallet} />
								<PrivateRoutes exact path='/consult' moduleName={ROLE_MODULES.CONSULT} component={Consultation} />
								<PrivateRoutes exact path='/profile' moduleName={ROLE_MODULES.PROFILE} component={ProfileManagement} />
								<PrivateRoutes exact path='/patientemr' moduleName={ROLE_MODULES.PATIENTEMR} component={ChartingBilling} />
								<PrivateRoutes exact path='/patientemr/:action/:id' moduleName={ROLE_MODULES.PATIENTEMR} component={ChartingBilling} />
								<PrivateRoutes exact path='/patientemr/:action/:id/:record' moduleName={ROLE_MODULES.PATIENTEMR} component={ChartingBilling} />
								<PrivateRoutes
									exact
									path={'/clients'}
									moduleName={groupName === 'health care' ? ROLE_MODULES.PATIENTS : ROLE_MODULES.CLIENTS}
									component={PatientCli}
								/>
								<PrivateRoutes
									exact
									path={'/clients/:action'}
									moduleName={groupName === 'health care' ? ROLE_MODULES.PATIENTS : ROLE_MODULES.CLIENTS}
									component={PatientCli}
								/>
								<PrivateRoutes
									exact
									path='/clientinfo/:id'
									moduleName={groupName === 'health care' ? ROLE_MODULES.PATIENTS : ROLE_MODULES.CLIENTS}
									component={groupName === 'health care' ? CustomerDetailsPatients : CustomerDetails}
								/>
								<PrivateRoutes
									exact
									path='/clientinfo/:action/:id'
									moduleName={groupName === 'health care' ? ROLE_MODULES.PATIENTS : ROLE_MODULES.CLIENTS}
									component={groupName === 'health care' ? CustomerDetailsPatients : CustomerDetails}
								/>
								<PrivateRoutes
									exact
									path='/clientinfo/:action/:id/:billing'
									moduleName={groupName === 'health care' ? ROLE_MODULES.PATIENTS : ROLE_MODULES.CLIENTS}
									component={groupName === 'health care' ? CustomerDetailsPatients : CustomerDetails}
								/>
								<PrivateRoutes exact path='/addclient' moduleName={ROLE_MODULES.CLIENTS} component={AddClient} />
								<PrivateRoutes exact path='/profilestatus' moduleName={ROLE_MODULES.PROFILE} component={ProfileStatus} />
								<PrivateRoutes exact path='/profilestatus/:action/:uuid/:mastuuid' moduleName={ROLE_MODULES.PROFILE} component={ProfileStatus} />
								<PrivateRoutes
									exact
									path='/profileunderverification/:action/:uuid/:mastuuid'
									moduleName={ROLE_MODULES.PROFILE}
									component={ProfileVerification}
								/>
								<PrivateRoutes exact path='/accountsetting' moduleName={ROLE_MODULES.ACCOUNT_SETTING} component={AccountSettings} />
								<PrivateRoutes exact path='/calendar' moduleName={ROLE_MODULES.CALENDAR} component={Calendar} />
								<PrivateRoutes exact path='/pharma' moduleName={ROLE_MODULES.PHARMACIST} component={PharmPres} />
								<PrivateRoutes exact path='/inventory' moduleName={ROLE_MODULES.INVENTORY} component={Inventory} />
								<PrivateRoutes exact path='/expenses' moduleName={ROLE_MODULES.EXPENSES} component={Expenses} />
								<PrivateRoutes exact path='/activities' moduleName={ROLE_MODULES.ACTIVITIES} component={Activities} />
								<PrivateRoutes exact path='/event/registeruser/:id' moduleName={ROLE_MODULES.SUBSCRIPTION} component={RegisterUserList} />
								<Route path='/expire' component={ExpirePlan} />
								<Route path='/otpverification' component={OtpVerification} />
								<PrivateRoutes exact path='/helpcenter' moduleName={ROLE_MODULES.SETTINGS} component={HelpCenter} />
								<PrivateRoutes exact path='/accountdetails' moduleName={ROLE_MODULES.MY_PROFILE} component={AccountDetails} />
								<PrivateRoutes exact path='/profilesetup' moduleName={ROLE_MODULES.PROFILE} component={ProfileSetup} />
								<PrivateRoutes exact path='/profilesetup/:action/:uuid/:mastuuid' moduleName={ROLE_MODULES.PROFILE} component={ProfileSetup} />
								{/* No need data */}
								{/* <PrivateRoutes exact path='/golive' moduleName={ROLE_MODULES.PROFILE} component={StepperView} /> */}
								<PrivateRoutes exact path='/readytogolive' moduleName={ROLE_MODULES.PROFILE} component={ProfileGoLive} />
								<PrivateRoutes exact path='/readytogolive/:action/:uuid/:mastuuid' moduleName={ROLE_MODULES.PROFILE} component={ProfileGoLive} />
								<PrivateRoutes exact path='/communication/:action/:id' moduleName={ROLE_MODULES.COMMUNICATION} component={Communication} />
								<PrivateRoutes
									exact
									path='/communication/template/:action/:id'
									moduleName={ROLE_MODULES.COMMUNICATION}
									component={WhatsappCenterView}
								/>
								<PrivateRoutes exact path='/communication/whatsapp' moduleName={ROLE_MODULES.COMMUNICATION} component={WhatsappCenterSend} />
								<PrivateRoutes exact path='/accountsubscription' moduleName={ROLE_MODULES.SUBSCRIPTION} component={AccountSubscription} />
								<PrivateRoutes
									exact
									path='/accountsubscription/cart/:action/:id'
									moduleName={ROLE_MODULES.SUBSCRIPTION}
									component={AccountSubscription}
								/>
								<PrivateRoutes exact path='/eventsmanagement' moduleName={ROLE_MODULES.SUBSCRIPTION} component={Events} />
								<PrivateRoutes
									exact
									path='/accountsubscription/practice/:action/:id'
									moduleName={ROLE_MODULES.SUBSCRIPTION}
									component={AccountSubscription}
								/>
								<PrivateRoutes exact path='/cart/plan-list/:action/:tentUuid' component={Cart} moduleName={ROLE_MODULES.SUBSCRIPTION} />
								<PrivateRoutes
									exact
									path='/cart/paymentstatus/:action/:tentUuid/:id'
									moduleName={ROLE_MODULES.SUBSCRIPTION}
									component={PaymentSuccess}
								/>
								<PrivateRoutes
									exact
									path='/accountsubscription/history/invoice/:action/:mastTentUuid/:tentUuid'
									moduleName={ROLE_MODULES.SUBSCRIPTION}
									component={InvoicHistory}
								/>
								<PrivateRoutes
									exact
									path='/accountsubscription/history/invoice/purchasedstatus/:action/:id/:tentUuid'
									moduleName={ROLE_MODULES.SUBSCRIPTION}
									component={PurchasedDetail}
								/>
								<PrivateRoutes exact path='/dashboardai' moduleName={ROLE_MODULES.DASHBOARDAI} component={DashbaordAI} />
								<PrivateRoutes exact path='/events' moduleName={ROLE_MODULES.EVENTS} component={EventsManagement} />
								<PrivateRoutes exact path='/events/:id' moduleName={ROLE_MODULES.EVENTS} component={CreateEventManagement} />
								<PrivateRoutes exact path='/eventinfo' moduleName={ROLE_MODULES.EVENTS} component={EventInfo} />

								<PrivateRoutes exact path='/chats' moduleName={ROLE_MODULES.CHAT_CONSULT} component={Chat} />
								<PrivateRoutes exact path='/chatTest' moduleName={ROLE_MODULES.CHAT_CONSULT} component={ChatTest} />
								<PrivateRoutes exact path='/leads' moduleName={ROLE_MODULES.LEADS} component={Leads} />
								<PrivateRoutes exact path='/leads/:action' moduleName={ROLE_MODULES.LEADS} component={Leads} />
								<PrivateRoutes exact path='/leads/:action/:id' moduleName={ROLE_MODULES.LEADS} component={LeadsCustomerDetails} />
								<PrivateRoutes exact path='/leadsinfo/:id' moduleName={ROLE_MODULES.LEADS} component={LeadsCustomerDetails} />
								<PrivateRoutes exact path='/cart/ordersummary/:action/:tentUuid' moduleName={ROLE_MODULES.SUBSCRIPTION} component={OrderSummary} />
								<PrivateRoutes exact path='/clientinfo/:custUuid' moduleName={ROLE_MODULES.BREATHING_RATE} component={BreathingRate} />
								{/* <PrivateRoutes excat path='/cart/paymentfailure/:action/:id' moduleName={ROLE_MODULES.SUBSCRIPTION} component={PaymentFailure} /> */}
								{/* <Route path='/PreviewForm' component={Previewform} /> */}
								<PrivateRoutes exact path='/attendance' moduleName={ROLE_MODULES.ATTENDANCE} component={AttendenceManagement} />
								<Route path='*' component={Error404} />

								{/* <Route path='*'>
									<Redirect to='/404' />
								</Route> */}
							</Switch>
						</ErrorBoundary>
						{props.children}
					</div>
					{config.footer.display && <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />}
				</main>

				{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
			</div>
			{config.rightSidePanel.display && <RightSideLayout1 />}
			<FuseMessage />
			<EndWalkthrough open={open} handleClose={onClickCloseTour} onSubmitTour={onSubmitTourClose} />
			{(_.isEqual(mobileNo, null) || _.isEqual(mobileNo, '')) && (currentPath === '/setting' || currentPath === '/communication/whatsapp') && (
				<GoogleSignInDialog open={openSuccessModal} setOpen={setOpenSuccessModal} history={history} />
			)}
			{/* {isAuthenticated && <CallAcceptDialog />} */}
		</Root>
	)
}

export default memo(Layout1)
